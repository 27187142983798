<template>
  <div id="app">
    <CodeScanner :session=session />
  </div>
</template>

<script>
import CodeScanner from './components/CodeScanner.vue'

export default {
  name: 'App',
  components: {
    CodeScanner
  },
  computed: {
    session() {
      const savedSession = localStorage.getItem("savedSession")
      return savedSession
    }
  }
}
</script>

<style lang="scss">
</style>
