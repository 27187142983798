<template>
  <div class="columns is-tablet is-centered">
    <div class="column is-half">
      <section class="section">
      <div class="container">
        <h1 class="title has-text-centered">QR Portal</h1>
          <div v-if="!hasSession"  class="input-wrapper has-text-centered" >
            <p class="is-size-7 is-family-code">Bitte geben Sie den PIN ein, welchen Sie beim Login mit Passwort erhalten haben.</p>

            <PincodeInput
              v-if="!hasSuccess"
              v-model="code"
              placeholder="0"
              :length=5
              @input="checkCode"
              v-bind:class="{ 'is-disabled': isDisabled }"
            />

            <b-loading v-model="isProcessing"></b-loading>
              <div v-if="isProcessing" class="is-flex is-flex-direction-column is-justify-content-center">              
            </div>

            <b-message class="mt-3" v-if="isProcessing" type="is-info">
              Der Pin wird geprüft. Bitte warten Sie einen kurzen Moment.
            </b-message>            

            <b-message class="mt-3" v-if="hasSuccess" type="is-success">
              Der Pin war korrekt.
            </b-message>

            <b-message class="mt-3" v-if="hasError" type="is-danger">
              Der Pin war falsch. Bitte versuchen Sie es erneut.
            </b-message>               

          </div>

          <div class="has-text-centered"  v-if="hasSession">
            
              <div class="box">
                <div v-if="resolved">
                  <div v-if="isTargeting" class="is-flex is-flex-direction-column is-justify-content-center px-3 py-3 mt-5 mb-5">
                    <div class="is-align-self-center is-loading loader is-colored-loader"></div>
                    <div class="mt-5 is-family-code has-text-weight-light is-size-7">QR Code wird verarbeitet...</div>              
                  </div>
                  <div v-if="!isTargeting">

                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                      viewBox="0 0 98.5 98.5" enable-background="new 0 0 98.5 98.5" xml:space="preserve">
                      <path class="checkmark" fill="none" stroke-width="8" stroke-miterlimit="10" d="M81.7,17.8C73.5,9.3,62,4,49.2,4
                      C24.3,4,4,24.3,4,49.2s20.3,45.2,45.2,45.2s45.2-20.3,45.2-45.2c0-8.6-2.4-16.6-6.5-23.4l0,0L45.6,68.2L24.7,47.3"/>
                    </svg>

                  </div>
                </div>
                <div v-else>
                  <p>Ungültige Eingabe.</p>
                </div>
              </div>
          </div>        
      </div>
    </section>
    <footer class="footer">
      <div class="has-text-centered">
        <p class="has-text-centered">
          Masterarbeit <strong>"They Call Me Jake"</strong>
        </p>
      </div>
    </footer>
    <div style="font-size:10px;background:#2c3e50;border-top:1px;" class="px-2 py-2">
        <p class="has-text-centered is-family-code has-text-light"><span style="font-size:15px;position:relative;top:2px;">©</span> Copyright Anna Fersztand 2021 · Made with <svg style="position:relative; top:2px;margin-right:1px;" xmlns="http://www.w3.org/2000/svg" width="12px" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd" d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z" clip-rule="evenodd" />
      </svg> by <a class="has-text-link-light" target="_blank" href="https://github.com/ekntrtmz">Ekin Tertemiz</a></p>
      </div>       
    </div>
  </div>
</template>
<script>
import PincodeInput from 'vue-pincode-input';
export default {
  name: 'CodeScanner',
  components: {
    PincodeInput,
  },
  data: function () {
    return {
      code: '',
      isProcessing: false,
      isDisabled: false,
      isTargeting: true,
      hasError: false,
      hasSuccess: false,
      hasSession: this.session
    }
  },
  props: {
    session: String
  },
  created() {
    this.axios.defaults.baseURL = 'https://ma.theycallmejake.ch/api'
  },
  mounted() {
    if(this.resolved) {
      this.resolveTarget();
    }
  },
  computed: {

    resolved() {
      const searched = this.search()
      const decoded = this.decode(searched)
      const parsed = this.parse(decoded)
      this.log(parsed)
      return parsed
    },
    currentSession() {
      if(!this.session) {
        return localStorage.getItem("savedSession");
      }
      return this.session;
    }
  },

  methods: {
    
    resetResolver() {
      localStorage.removeItem("savedSession");
      this.hasSession = false;
      this.resetPin();
    },
    
    resetPin() {
      this.code = '';
    },

    async resolveTarget() {

      var data = {
          session_id: localStorage.getItem("savedSession"),
          target: this.resolved.target,
        }

      this.axios({
          method: 'post',        
          url: 'resolve',
          dataType: 'json',
          crossdomain: true,
          headers: {
          'Content-Type': 'application/json'
          },
          data: data
        })
        .then(response => {
        console.log(response)
        this.isTargeting = false;
        })
        .catch(e=> {
          console.log(e)
          this.isTargeting = false;
          this.resetResolver();
        })

    },

    async checkCode() {
      if(this.code.length == 5) {
        
        this.isProcessing = true;
        this.isDisabled = true;
        document.activeElement.blur();

        var data = {
          pin: this.code
        }

        this.axios({
          method: 'post',        
          url: 'check',
          dataType: 'json',
          crossdomain: true,
          headers: {
          'Content-Type': 'application/json'
          },
          data: data
        })
        .then(response => {
          console.log(response);
          this.hasError = false;
          this.hasSuccess = true;

          //  Save session id to localhost
          localStorage.setItem("savedSession", response.data.s_id);

          setTimeout( () => {
            this.hasSession = true;
            if(this.resolved) {
              this.resolveTarget();
            }
          }, 2500);
        })
        .catch(e=> {
          console.log(e.response);
          this.hasError = true;
          this.isDisabled = false;
          this.resetPin();
        })
        .finally( () => {
          this.isProcessing = false;
        })

      }
    },
    search() {
      //  Retrieve parameter "resolve" from URL
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      this.log("Search: "+ params.resolve)
      return params.resolve; 
    },
    decode(content) {
      try {
        return atob(content)
      } catch(error) {
        this.log("Decode Error: "+error)
        return false
      }
    },
   parse(content) {
      try {
        return JSON.parse(content)
      } catch(error) {
        this.log("Parse Error: "+error)
        return false
      }
    },
    log(msg) {
      if(process.env.NODE_ENV == "development") {
        console.log(msg)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css">
  div.vue-pincode-input-wrapper {
    padding: 25px;
  }

  input.vue-pincode-input {
    font-size: 55px;
    box-sizing: content-box;
    margin: 3px;
    border-radius: 15px;
    color:#4a4a4a;
    font-family: monospace !important;
  }

  div.vue-pincode-input-wrapper.is-disabled input.vue-pincode-input {
    pointer-events: none;
    opacity: 0.5;
  }

    .wrapper {
      width: 100px;
      margin: 4em auto 0;
    }

    .checkmark {
      stroke: #00d1b2;
      stroke-dashoffset: 745.7485351563;
      stroke-dasharray: 745.7485351563;
      -webkit-animation: dash 2s ease-out forwards 1;
              animation: dash 2s ease-out forwards 1;
    }

    @-webkit-keyframes dash {
      0% {
        stroke-dashoffset: 745.7485351563;
      }
      100% {
        stroke-dashoffset: 0;
      }
    }

    @keyframes dash {
      0% {
        stroke-dashoffset: 745.7485351563;
      }
      100% {
        stroke-dashoffset: 0;
      }
    }  


footer {
  padding: 2rem 1.5rem 1.5rem!important;
}


</style>
